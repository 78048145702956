<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-link to="/logout">Logout</router-link>
  </nav> -->
  <div v-if="updateExists">
    <nav class="navbar navbar-expand-sm bg-danger">

<!-- Links -->
<ul class="navbar-nav">
  <li class="nav-item">
    <a class="nav-link" href="#" @click="refreshApp()">
      New Update {{ buildVersion }} available please click to update 
    </a>
  </li>
   
</ul>

</nav>
  </div>
  <component :is="this.$route.meta.layout || 'div'">
        <b-container
          :toast="{root: true}"
          fluid="sm" 
          ></b-container>
          
           <router-view></router-view>
           
        </component>
  
</template>
<script>
import update from '@/mixins/update';
import { computed } from 'vue';
export default{

  mixins:[update],
  computed:{
    buildVersion:function(){
      return process.env.VUE_APP_VERSION;
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} 

nav {
  padding:5px;
}

nav a {
  font-weight: bold !important;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
label.form-label{
  font-weight: bolder;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>