<template> 
    <div class="p-5 bg-primary text-white text-center">
        <h1>Elands Nursery</h1>
        <p>Growing into the future</p> 
    </div>
</template>
<script>
export default {
  name:"AuthHeader",
  data() {
    return {
    };
  },
  methods:{ 
  } 
}
</script>