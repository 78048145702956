
export const state = {
    search:"",
    categories:[] ,
    page_num:0,
    scroll:0,
    jsonData:""
}

export const mutations = {
    loadSearch(){
        let obj = JSON.parse(localStorage.getItem("product_view"));
        if(obj){
            state.search =obj.search; 
            state.categories =obj.categories; 
            state.page_num =obj.page_num; 
        }else{
            state.search =""; 
            state.categories =[]; 
            state.page_num =9; 
        }
    },
    saveScroll(state,scroll){
        state.scroll=scroll;
        this.commit("productView/_saveItemState") 
    },
    saveSearch(state,args){
        state.search = args[0];
        state.categories = args[1];
        state.page_num = args[2];
        
        this.commit("productView/_saveItemState") 
    },
    clear(state){
        state.search = "";
        state.categories = [];
        state.page_num = 9;
        state.scroll=0;
        this.commit("productView/_saveItemState") 
    },
    _saveItemState(state){
        localStorage.setItem("product_view",JSON.stringify(state));
        let dataObj = {"search":state.search,"categories":state.categories,"page_num":state.page_num}
        state.jsonData = JSON.stringify(dataObj)
    }
}