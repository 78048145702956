import {baseDataService} from "@/services/baseData.service";
//import grouping from "./salesGrouping.json";
export const state = {
    salesArea:[] ,
    salesGrouping:[]
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async getSalesReps({state,commit}){
        try { 
            if(state.salesArea && state.salesArea.length==0){
                let prod = await baseDataService.listSalesAreas(); 
                commit('setSalesArea', prod);
                return prod;
            }
            return state.salesArea;
        } catch (error) {
            console.error("List categories",error);
            commit('loadSalesArea');
            
        }
        return state.salesArea
    },
    async getSalesGrouping({state,commit}){
        try { 
            if(state.salesGrouping && state.salesGrouping.length==0){
                let prod = await baseDataService.listSales_grouping(); 
                commit('setSalesGrouping', prod);
                return prod;
            }
            return state.salesGrouping;
        } catch (error) {
            console.error("List categories",error);
            commit('loadSalesArea');
            
        }
        return state.salesArea
    },
    async getAllSalesGrouping({state,commit}){
        try { 
             
            let prod = await baseDataService.listSales_grouping(); 
            commit('setSalesGrouping', prod);
            return prod; 
        } catch (error) {
            console.error("List categories",error);
            commit('loadSalesArea');
            
        }
        return state.salesArea
    },
    async getSalesAreaByCustomerCategory({state,commit},CustomerCategory){
        const oSales = state.salesArea.find(x=>x.CustomerCategory==CustomerCategory);
        if(oSales && oSales.Name){
            return oSales;
        }
        mutations.loadSalesArea();
        oSales = state.salesArea.find(x=>x.CustomerCategory==CustomerCategory);
        if(oSales && oSales.Name){
            return oSales;
        }
        if(state.salesArea && state.salesArea.length==0){
            await actions.getSalesReps({state,commit});
            return state.salesArea.find(x=>x.CustomerCategory==CustomerCategory);
        }
    },
}
export const mutations = {
    
    setSalesArea(state, salesArea) {
        state.salesArea = salesArea; 
        localStorage.setItem("salesArea",JSON.stringify(salesArea)); 
    },
    setSalesGrouping(state, salesGrouping) {
        let mapData = salesGrouping.map(x=>{
            x.Group = JSON.parse(x.Group);
            return x;
        });
        state.salesGrouping = mapData; 
        localStorage.setItem("salesGrouping",JSON.stringify(mapData)); 
    },
    loadSalesArea(state) {
        let salesArea = JSON.parse(localStorage.getItem("salesArea"));
        if(salesArea){
            state.salesArea = salesArea; 
        }
    },
    loadSalesGrouping(state) {
        let salesGrouping = JSON.parse(localStorage.getItem("salesGrouping"));
        if(salesGrouping){
            state.salesGrouping = salesGrouping; 
        }
    },
}