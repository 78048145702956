<template>
<div>
  <nav class="navbar navbar-expand-sm bg-dark navbar-dark d-none d-md-block d-lg-block d-xl-block d-xxl-block">
    <div class="container-fluid">
      <ul class="navbar-nav nav-pills">
        <li class="nav-item pr-3">
          <img src="~@/assets/logo_small.webp" class="headerImg"/>
        </li>
        <li class="nav-item pt-2-5">
          <router-link to="/items/list" class="nav-link active">Products</router-link> 
        </li>
        
         
      </ul>
    </div>
  </nav>
  <nav class="navbar navbar-expand-sm bg-dark navbar-dark d-block d-sm-none">
    <div class="container-fluid">
      <ul class="navbar-nav nav-pills">
        <li class="nav-item pr-3">
          <img src="~@/assets/logo_small.webp" class="headerImg"/>
        </li> 
        <!-- <li class="nav-item pt-2-5">
          <router-link to="/" class="nav-link active">Home</router-link> 
        </li>
        
        <li class="nav-item pt-2-5" v-for="(item, index) in userMenu[0].items" :key="item.heading">
          <router-link :to="menuHeading(index)" class="nav-link">{{item.heading}}</router-link> 
        </li> 
        <li class="nav-item pt-2-5">
          <router-link to="/logout" class="nav-link">Logout</router-link> 
        </li> -->
      </ul>
    </div>
  </nav>

</div>
</template>

<script> 
export default {
  name:"topBar",
  computed: {
    userMenu() {
      return this.$store.state.auth.userMenu;
    },
    FullUserName() {
      return this.$store.state.auth.FullUserName;
    },
  },
  data() {
    return {
    };
  },
  methods:{ 
    menuHeading(index){
      return `/menu/0/${index}`
    }
  } 
}
</script>
<style>
.headerImg{
  max-width: 80px;
  min-width: 25px;
}
.pt-2-5, .pt-2-5 {
    padding-top: 0.8rem!important;
}
</style>