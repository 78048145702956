export const state = {
    showPictureURL:"",
    NewPicture:false,
    pictureKey:""
}

export const mutations = {
    setNewPicture(state, pictureSrc) {
        state.pictureKey = new Date().toLocaleTimeString();
        state.showPictureURL = pictureSrc;
        state.NewPicture = true;
    },
    hide(state){
        state.NewPicture = false;
    }

}
