export const state = {
    SalesOrderData:[]
}


export const mutations = {
    setSalesOrderList(state,SalesOrderData){
        state.SalesOrderData = SalesOrderData.filter(x=>x.Quantity>0);
    },
    updateSalesOrder(state,args){
        state.SalesOrderData = state.SalesOrderData.filter(x=>x.idsalsOrderHeader==args[0]);
        state.SalesOrderData.splice(0,0,args[1]);
    },
    updateLineQty(state,args){
        state.SalesOrderData[args[0]].Quantity = args[1];
    },
    updateLineComment(state,args){
        state.SalesOrderData[args[0]].Comment = args[1];
    },
    deleteLine(state,args){
        state.SalesOrderData[args[0]].isDeleted = 1;
        state.SalesOrderData = state.SalesOrderData.filter(x=>x.isDeleted!=1);
    }
}