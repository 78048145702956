import {baseDataService} from "@/services/baseData.service";

export const state = {
    categories:[]
}
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async list({commit}) {         

        try { 
            let cats = await baseDataService.categories();
            cats = cats.map(x=>{x.Action=false;return x;})
                       .sort((a, b) => a.SortIndex - b.SortIndex)
            commit('setCategories', cats);
            return cats;
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
    },
    async listDBLast({state,commit}) {         

        try {
            console.log("Try get from DB")
            if(state.categories && state.categories.length==0){
                let cats = await baseDataService.categories();
                cats = cats.map(x=>{x.Action=false;return x;})
                commit('setCategories', cats);
                return cats;
            }
            return state.categories;
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
    }
}
// export const getters = {
//     // Whether the user is currently logged in.
//     categories() {
//         let allCategories = localStorage.getItem("categorieslist");
//         if(allCategories && allCategories.length){
            
//             return allCategories;
//         } 
//     },
// }
export const mutations = {
    setCategories(state, Categories) {
        state.categories = Categories; 
        localStorage.setItem("categorieslist",JSON.stringify(Categories));
         
    },
    load_storage(state){
        let allCategories = localStorage.getItem("categorieslist");
        if(allCategories && allCategories.length){            
            state.categories = allCategories;
        }  
    }
}

