 

onmessage = (e) => {
  if(e.data && e.data.length && e.data.length>1){
    //console.log("Message received from main script","ar_" + e.data[0]+"_contact");

    //localStorage.setItem("ar_" + e.data[0]+"_contact",);
    //const workerResult = `Result: ${e.data[0] * e.data[1]}`;
    //console.log("Posting message back to main script");
    if(e.data[1] && e.data[0]){
      setTimeout((key,value)=>{
        let text = value||'';
        if(text.length>10){          
          try {
            const dataOut =JSON.stringify(text)
            postMessage([key,dataOut]);
          } catch (errorCatch) {
            console.error(errorCatch); 
          }          
        }else{
          //console.error("Not correct data ",value,key); 
        }
      },Math.floor(Math.random() * 300),e.data[0],e.data[1]);
    }
    
  }
    
  //   _.delay(function({key1,value1}){
  //   //postMessage([e.data[0],JSON.stringify(e.data[1])]);
  //   postMessage([key1,JSON.stringify(value1)]);
  // },Math.floor(Math.random() * 300),{key1:e.data[0],value1:e.data[1]});
  };
  