import * as JsSearch from 'js-search';
import { ref } from 'vue'; 
export default {
    install: (app) => { 
       
      // create product search int
      app.config.globalProperties.$productSearch = new JsSearch.Search('Description');
      app.config.globalProperties.$productSearch.addIndex('Article');
      app.config.globalProperties.$productSearch.addIndex('Category');
      app.config.globalProperties.$productSearch.addIndex('Code');
      app.config.globalProperties.$productSearch.addIndex('Description');
      app.config.globalProperties.$productSearch.addIndex('Description2');
      app.config.globalProperties.$productSearch.addIndex('InventoryGroup');
      app.config.globalProperties.$productSearch.addIndex('Label');
      app.config.globalProperties.$productSearch.addIndex('ProductCode');
      app.config.globalProperties.$productSearch.addIndex('Tags'); 
    },
  };
  