import main from '../views/layouts/main.vue'
import noAuthLayout from '../views/layouts/NoAuth.vue'
import ProductList from '../views/layouts/ProductList.vue'

import store from '@/store';
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            layout: noAuthLayout,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home',
                    });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: () => import('../views/pages/unsubscribe/index'),
        meta: {
            layout: noAuthLayout 
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../views/pages/privacyPolicy/index'),
        meta: {
            layout: noAuthLayout 
        },
    },
    {
        path: '/loadata',
        name: 'LoadData',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/HomeView.vue'),
    },     
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/dashboard/index'),
    },
    {
        path: '/menu/:idex/:subidex',
        name: 'menulist',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/dashboard/menu'),
    },
    {
        path: '/orders/create_order',
        name: 'CreateOrder',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/create_order'),
    },
    {
        path: '/orders/active_order',
        name: 'ActiveOrder',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/active_order'),
    },
    {
        path: '/orders/active_order/:id/edit',
        name: 'ActiveOrderEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/active_order/OrderDetails.vue'),
    },
    {
        path: '/orders/offline_order',
        name: 'orderOffLine',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/offline_order'),
    },
    {
        path: '/orders/new_orders',
        name: 'orderNewOrders',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/new_orders'),
    },
    {
        path: '/orders/new_orders/:areaID',
        name: 'orderNewOrdersDetails',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/new_orders/loadSalesData.vue'),
    },
    {
        path: '/orders/new_orders/:areaID/details',
        name: 'orderNewOrdersDetails1',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/new_orders/details.vue'),
    },
    {
        path: '/orders/new_orders/:areaID/parts',
        name: 'orderNewOrdersDetails2',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/new_orders/parts.vue'),
    },
    {
        path: '/orders/new_orders/:areaID/:id',
        name: 'orderNewOrdersDetailsEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/new_orders/editOrder.vue'),
    },
    {
        path: '/orders/planed_orders',
        name: 'orderPlanedOrder',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/planed_orders'),
    },
    {
        path: '/orders/planed_orders/:areaID',
        name: 'orderPlanedOrderDetails',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/planed_orders/loadSalesData.vue'),
    },
    {
        path: '/orders/planed_orders/:areaID/details',
        name: 'orderPlanedOrderDetails1',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/planed_orders/details.vue'),
    },
    {
        path: '/orders/planed_orders/:areaID/parts',
        name: 'orderPlanedOrderDetails2',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/planed_orders/parts.vue'),
    },
    {
        path: '/orders/planed_orders/:areaID/:id',
        name: 'orderPlanedOrderDetailsEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/planed_orders/editOrder.vue'),
    },
    {
        path: '/orders/ship_orders',
        name: 'orderShipOrders',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/ship_orders'),
    },
    {
        path: '/orders/reports',
        name: 'order_reports',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/reports/index.vue'),
    },
    {
        path: '/orders/reports/:type',
        name: 'order_reportsData',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/orders/reports/tableData.vue'),
    },
    {
        path: '/products/admin',
        name: 'productAdmin',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/admin/index.vue'),
    },
    {
        path: '/products/admin/create',
        name: 'productAdminCreate',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/admin/create.vue'),
    },
    {
        path: '/products/admin/:id/edit',
        name: 'productAdminEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/admin/edit.vue'),
    },
    {
        path: '/products/list/:productsearch',
        name: 'productListSearch',
        meta: {
            authRequired: true,
            layout: main,
            readonly:false
        },
        component: () => import('../views/pages/products/list'),
    },
    {
        path: '/products/list',
        name: 'productList',
        meta: {
            authRequired: true,
            layout: main,
            readonly:false
        },
        component: () => import('../views/pages/products/list'),
    },
    {
        path: '/products/list/:id/details/:productsearch',
        name: 'productDetails',
        meta: {
            authRequired: true,
            layout: main,
            readonly:false
        },
        component: () => import('../views/pages/products/details'),
    },
    {
        path: '/products/list/:id/customer/:qty/:productsearch',
        name: 'productCustomerSelectWithQty',
        meta: {
            authRequired: true,
            layout: main,
            readonly:false
        },
        component: () => import('../views/pages/products/customerSelect'),
    },
    {
        path: '/products/list/customer',
        name: 'productCustomer',
        meta: {
            authRequired: true,
            layout: main,
            readonly:false
        },
        component: () => import('../views/pages/products/customerSelect'),
    },
    {
        path: '/products/categories',
        name: 'Categories',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/categories/index.vue'),
    },
    {
        path: '/products/categories/create',
        name: 'CategoriesCreate',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/categories/create.vue'),
    },
    {
        path: '/products/categories/:id/edit',
        name: 'CategoriesEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/categories/edit.vue'),
    },
    {
        path: '/products/types',
        name: 'product_type',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/types/index.vue'),
    },
    {
        path: '/products/types/create',
        name: 'product_type_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/types/create.vue'),
    },
    {
        path: '/products/types/:id/edit',
        name: 'product_type_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/types/edit.vue'),
    },
    {
        path: '/products/imageupload',
        name: 'ProducUpload',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/imageupload/index.vue'),
    },
    {
        path: '/products/imageCheck',
        name: 'imageCheck',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/imageCheck/index.vue'),
    },
    {
        path: '/products/productImport',
        name: 'productImport',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/products/productImport/index.vue'),
    },
    {
        path: '/cart/list',
        name: 'cartList',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/cart/index.vue'),
    },
    {
        path: '/cart/address',
        name: 'cartAddress',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/cart/OrderCompleteSteps.vue'),
    },
    {
        path: '/items/list',
        name: 'ReadOnlyProducts',
        meta: {
            authRequired: false,
            layout: ProductList,
            readonly:true
        },
        component: () => import('../views/pages/products/list'),
    },
    {
        path: '/items/list/:productsearch',
        name: 'ReadOnlyProducts1',
        meta: {
            authRequired: false,
            layout: ProductList,
            readonly:true
        },
        component: () => import('../views/pages/products/list'),
    },
    {
        path: '/items/list/:id/details/:productsearch',
        name: 'ReadOnlyproductDetails',
        meta: {
            authRequired: false,
            layout: ProductList,
            readonly:true
        },
        component: () => import('../views/pages/products/details'),
    },
    {
        path: '/account/users',
        name: 'admin_users',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/account/users'),
    },
    {
        path: '/account/users/create',
        name: 'admin_users_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/account/users/create'),
    },
    {
        path: '/account/users/:id/edit',
        name: 'admin_users_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/account/users/edit'),
    }, 
    {
        path: '/customerMaster',
        name: 'customerMaster',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerMaster/index.vue'),
    },
    {
        path: '/customerMaster/create',
        name: 'customerMaster_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerMaster/create'),
    },
    {
        path: '/customerMaster/:id/edit',
        name: 'customerMaster_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerMaster/edit'),
    },
    {
        path: '/customerMasterImport',
        name: 'customerMasterImport',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerMaster/upload/index.vue'),
    },     
    {
        path: '/contact_header',
        name: 'contact_header_list',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/contactHeader/index.vue'),
    }, 
    {
        path: '/contact_header/:id/edit',
        name: 'contact_header_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/contactHeader/edit.vue'),
    }, 
    {
        path: '/contact_header/create',
        name: 'contact_header_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/contactHeader/create.vue'),
    }, 
    {
        path: '/quick_areas',
        name: 'quick_areas',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/quick_areas/index.vue'),
    },
    {
        path: '/quick_areas/create',
        name: 'quick_areas_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/quick_areas/create.vue'),
    },
    {
        path: '/quick_areas/:id/edit',
        name: 'quick_areas_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/quick_areas/edit.vue'),
    },
    {
        path: '/crm/list',
        name: 'crm_list',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/index.vue'),
    },   
    {
        path: '/crm/:id/details',
        name: 'crm_details',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/Details/index.vue'),
    },  
    {
        path: '/crm/:id/contacts',
        name: 'crm_contacts',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/contacts/index.vue'),
    },
    {
        path: '/crm/:id/address',
        name: 'crm_address',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/address/index.vue'),//elandsweb/main-app/src/views/pages/crm/adress/index.vue
    },
    {
        path: '/crm/:id/orders',
        name: 'crm_orders',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/orders/index.vue'),
    },  
    {
        path: '/crm/:id/orders/:orderid/edit',
        name: 'crm_ordersDetails',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/orders/OrderDetails.vue'),
    },     
    {
        path: '/crm/contactBook',
        name: 'crm_contactList',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/contactBook/index.vue'),
    },
    {
        path: '/crm/contactBook/create',
        name: 'crm_contactListCreate',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/contactBook/create.vue'),
    },
    {
        path: '/crm/contactBook/edit',
        name: 'crm_contactListEdit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/contactBook/edit.vue'),
    },
    {
        path: '/crm/reports',
        name: 'crm_reports',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/reports/index.vue'),
    },    
    {
        path: '/crm/reports/:type',
        name: 'crm_reportsData',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/reports/tableData.vue'),
    },
    {
        path: '/crm/autoFilter',
        name: 'crm_auto_filter',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/autoFilter/index.vue'),
    },
    {
        path: '/crm/autoFilter/create',
        name: 'crm_auto_filter_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/autoFilter/create.vue'),
    },
    {
        path: '/crm/autoFilter/edit/:id',
        name: 'crm_auto_filter_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/autoFilter/edit.vue'),
    },
    {
        path: '/crm/send_email',
        name: 'crm_send_email',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/crm/sendEmail/index.vue'),
    },
    {
        path: '/customer_types',
        name: 'customer_types',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerTypes/index.vue'),
    },
    {
        path: '/customer_types/create',
        name: 'customer_types_create',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerTypes/create.vue'),
    },
    {
        path: '/customer_types/:id/edit',
        name: 'customer_types_edit',
        meta: {
            authRequired: true,
            layout: main,
        },
        component: () => import('../views/pages/customerTypes/edit.vue'),
    },
    {
        path: '/uploadTest',
        name: 'uploadTest',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "upload" */ '../views/UploadTest.vue')
      },
    {
        path: '/Update',
        name: 'Update',
        meta: {
            authRequired: true,
            layout: main,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "upload" */ '../views/pages/forceupdate.vue')
      },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        layout: noAuthLayout,
        authRequired: false, 
    },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/pages/account/logout/index.vue')
    }
  ]
  