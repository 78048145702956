import { ref } from 'vue'; 
export default {
    install: (app) => { 
      // Add a reactive property to the global properties
      const isOnline = ref(navigator.onLine);
  
      // Update the property when the connection status changes
      window.addEventListener('online', () => { 
        isOnline.value = true; 
        app.config.globalProperties.$store.commit('auth/setOnline',isOnline.value);
      });
      window.addEventListener('offline', () => { 
        isOnline.value = false; 
        app.config.globalProperties.$store.commit('auth/setOnline',isOnline.value);
      });
  
      // Make the property globally available
      app.config.globalProperties.$isOnline = isOnline;
    },
  };
  