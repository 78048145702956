import {baseDataService} from "@/services/baseData.service";

export const state = {
    products:[],
    PlantTypes:[],
    Suppliers:["Elands","Atlantic","LVG","Avendore","Malanseuns"]
}
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async list({commit}) {         

        try {
            let prod = await baseDataService.products();
             
            commit('setProducts', prod);
            return prod;
        } catch (error) {
            commit('load_storage');
        }
    },
    async listPlantTypes({state,commit}){
        try { 
            let prod = await baseDataService.listPlantType(); 
            commit('setPlantTypes', prod);
            return prod; 
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
        return state.products
    },
    async listPlantTypesDBLast({state,commit}){
        try {
             
            console.log("Try get from DB")
            if(state.PlantTypes && state.PlantTypes.length==0){
                let prod = await baseDataService.listPlantType(); 
                commit('setPlantTypes', prod);
                return prod;
            }
            return state.PlantTypes;
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
        return state.products
    },
    async listDBLast({state,commit}) {         

        try {
            
            console.log("Try get from DB")
            if(state.products && state.products.length==0){
                let prod = await baseDataService.products(); 
                 
                commit('setProducts', prod);
                return prod;
            }
            return state.products;
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
        return state.products
    } 
}
// export const getters = {
//     Whether the user is currently logged in.
//     products(Id) {
//         let allProductsId = localStorage.getItem(Id);
//         if(allProductsId && allProductsId.length){
//             let allProducts = [];
//             JSON.parse(allProductsId).forEach(c=>{
//                 let p = localStorage.getItem(c);
//                 if(p && p!=null){
//                     allProducts.push(JSON.parse(p));
//                 }
//             });
//             commit('load_storage');
//             return allProducts;
//         } 
//     },
// }
export const mutations = {
    setImageId(state,args){
        let idx = state.products.findIndex(x=>x.idproduct==args[0]);
        if(idx==-1){
            let p = JSON.parse(localStorage.getItem(args[0]));
            state.products.push(p);
            idx = state.products.findIndex(x=>x.idproduct==args[0]);
        }
        
        if(idx!=-1){
            state.products[idx].idProductImages=args[1];
            localStorage.setItem(state.products[idx].idproduct,JSON.stringify(state.products[idx]));
        }
    },
    setProducts(state, products) {
         
        state.products = products;
        let allProductKeys = products.map(x=>x.idproduct);
        localStorage.setItem("productsid",JSON.stringify(allProductKeys));
        products.forEach(x => {
            this.$productSearch.addDocument(x);
            localStorage.setItem(x.idproduct,JSON.stringify(x));
        });
        
    },
    setPlantTypes(state, PlantTypes) {
        state.PlantTypes = PlantTypes; 
    },
    load_storage(state){
        let allProductsId = JSON.parse(localStorage.getItem("productsid"));
        if(allProductsId && allProductsId.length){
            let allProducts = [];
            for (let index = 0; index < allProductsId.length; index++) {
                const c = allProductsId[index];
                let p = JSON.parse(localStorage.getItem(c));
                if(p && p.idproduct){
                    allProducts.push(p);
                    this.$productSearch.addDocument(p); 
                }
            } 
            state.products = allProducts;
        } 
    }
}

