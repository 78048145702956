import {baseDataService} from "@/services/baseData.service";
export const state = {
    users:[] 
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async getUsers({state,commit}){
        try { 
            if(state.users && state.users.length==0){
                let prod = await baseDataService.listUsersOnly(); 
                commit('setUsers', prod);
                return prod;
            }
            return state.users;
        } catch (error) {
            console.error("List categories",error);
            commit('loadUsers'); 
        }
        return state.users
    },    
}


export const mutations = {
    
    setUsers(state, Users) {
        state.users = Users; 
        localStorage.setItem("users",JSON.stringify(Users)); 
    },
    loadUsers(state) {
        let users = JSON.parse(localStorage.getItem("users"));
        if(users){
            state.users = users; 
        }
    },
}