<template>
<div class="mt-5 p-4 bg-dark text-white text-center">
  <div><font-awesome-icon icon="far fa-copyright" />  Copyright Elands Nursery {{currentYear}} </div>
  <div class="small font-italic text-right">
    Version: {{ buildVersion }}
  </div>
</div>
</template>

<script> 
export default {
  name:"FooterBottom",
  computed:{
    currentYear(){
      return new Date().getFullYear()
    },
    buildVersion:function(){
      return process.env.VUE_APP_VERSION;
    }
  },
  data() {
    return {
    };
  },
  methods:{ 
  } 
}
</script>