import {baseDataService} from "@/services/baseData.service";
//import saveContacts from "@/workers/saveContacts"
import _ from 'lodash';


// const saveWorker = new Worker(new URL("./workers/saveContacts", import.meta.url),
// {type: 'module'});
// setTimeout(()=>{
//     saveWorker.on("message", result => {
//         localStorage.setItem("ar_" + result[0]+"_contact",result[1]);
//     });
// },3000)


export const state = {
    customers:[],
    saleReps:[],
    contactTypes:[],
    customerContacts:{},
    customerTypes:[],
    autoFilter:[]
}
export const getters = {
    getCustomer: (state) => (id) => {
        // console.log("Looking for ",id,state.all.length)
        return state.customers.find((customer) => customer.idCustomerMaster == id);
    },
    getCRMCustomer(state) {
        return state.customers.filter((customer)=> customer.crmEnable==0)
    },
    getSalesCustomer(state) {
        return state.customers.filter((customer)=> customer.CanOrder==0)
    }
};
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async getSalesReps({state,commit}){
        try { 
            if(state.saleReps && state.saleReps.length==0){
                let prod = await baseDataService.listSalesReps(); 
                commit('setSalesReps', prod);
                return prod;
            }
            return state.saleReps;
        } catch (error) {
            console.error("List categories",error);
        }
        return state.saleReps
    },
    async getCustomerTypes({state,commit}){
        try { 
            if(state.customerTypes && state.customerTypes.length==0){
                let prod = await baseDataService.listCustomerTypes(); 
                commit('setCustomerTypes', prod);
                return prod;
            }
            return state.customerTypes;
        } catch (error) {
            console.error("List customerTypes",error);
        }
        return state.customerTypes
    },
    async getAutoFilter({state,commit}){
        try { 
            if(state.autoFilter && state.autoFilter.length==0){
                let prod = await baseDataService.listCrm_autoFilter(); 
                commit('setAutoFilter', prod);
                return prod;
            }
            return state.autoFilter;
        } catch (error) {
            console.error("List autoFilter",error);
        }
        return state.autoFilter
    },
    async listDBLast({state,commit}) {         

        try { 
            if(state.customers && state.customers.length==0){
                let prod = await baseDataService.listSalesRepCustomers(); 
                commit('setCustomers', prod);
                return prod;
            }
            return state.customers;
        } catch (error) {
            console.error("List categories",error)
            commit('load_storage');
        }
        return state.customers
    },
    async listCustomerContacts({state,commit}) {         

        try {  
            if(state.customerContacts && Object.keys(state.customerContacts).length==0){
                let prod = await baseDataService.listAllCustomer_Contacts(); 
                //console.log("Got constacts");
                commit('setCustomersContacts', prod);
                //console.log("return");
                return prod;
            }
            return state.customerContacts;
        } catch (error) {
            console.error("List customerContacts",error)
            commit('load_storage');
        }
        return state.customerContacts
    },
    async getCustomer({state,commit},customerID){
        let p = localStorage.getItem("ar_" + customerID);
        if(p && p.idCustomerMaster){
            return p;
        }
        if(state.customers && state.customers.length==0){
            let custlist = await actions.listDBLast({state,commit});
            return custlist.find((customer) => customer.idCustomerMaster == customerID);
        }
        return state.customers.find((customer) => customer.idCustomerMaster == customerID);
    },
    async getCustomerContact({state,commit},customerID){
          
        if(state.customerContacts[customerID]){
            return state.customerContacts[customerID];
        }
        let p = localStorage.getItem("ar_" + customerID +"_contact");
        if(p && p.length>0){
            return JSON.parse(p);
        }
        if(state.customerContacts && Object.keys(state.customerContacts).length==0){
            await actions.listCustomerContacts({state,commit});
            return state.customerContacts[customerID];
        }
        return state.customerContacts[customerID];
    },
    async getContactTypes({state,commit}){
        try { 
            if(state.contactTypes && state.contactTypes.length==0){
                let prod = await baseDataService.listContactHeaders(); 
                commit('setContactHeaders', prod);
                return prod;  
            }
            return state.contactTypes; 
        } catch (error) {
            console.error("List Contact Types",error);
        }
        let ch = JSON.parse(localStorage.getItem("contactTypes"));
        if(ch.length>0){
            return ch;
        }
        return state.contactTypes;
    }
}
export const mutations = {
    setCustomers(state, customers) {
        state.customers = customers;
        let allProductKeys = customers.map(x=>x.idCustomerMaster);
        localStorage.setItem("idCustomerMaster",JSON.stringify(allProductKeys));
        customers.forEach(x => {
            localStorage.setItem("ar_" + x.idCustomerMaster,JSON.stringify(x));
        });    
    },
    setCustomersDetails(state, Customer) {
        let idex = state.customers.findIndex(x=>x.idCustomerMaster==Customer.idCustomerMaster);
        if(idex!=-1){
            state.customers[idex] = Customer;
            localStorage.setItem("ar_" + Customer.idCustomerMaster,JSON.stringify(Customer));
        }
          
    },
     
    setCustomersContacts(state, customerContacts) {
        //debugger;
        //console.log("start Save");
        let group = _.groupBy(customerContacts,c=>c.idCustomerMaster);
        state.customerContacts ={}; 
        //console.log("start end group");
        _.forEach(group, function(value, key) { 
            setTimeout((key,value)=>{
                localStorage.setItem("ar_" + key+"_contact",JSON.stringify(value));
            },Math.floor(Math.random() * 300),key,value);
            //_.delay(function({key1,value1}){
            //    localStorage.setItem("ar_" + key1+"_contact",JSON.stringify(value1));
            //},Math.floor(Math.random() * 300),{key1:key,value1:value});
            //saveWorker.postMessage([key,value]);
            //state.customerContacts[key] = value;
            //debugger;
        });
        //console.log("end save");  
    },
    setSalesReps(state, saleReps) {
        state.saleReps = saleReps;
    },
    setCustomerTypes(state, customerTypes) {
        state.customerTypes = customerTypes;
    },
    setAutoFilter(state, autoFilters) {
        state.autoFilter = autoFilters;
    },
    load_storage(state){
        let allProductsId = JSON.parse(localStorage.getItem("idCustomerMaster"));
        if(allProductsId && allProductsId.length){
            let allProducts = [];
            let address = {};
            allProductsId.forEach(c=>{
                let p = JSON.parse(localStorage.getItem("ar_" + c));
                if(p && p.idCustomerMaster){
                    allProducts.push(p);
                }
                let a = JSON.parse(localStorage.getItem("ar_" + c+"_contact"));
                if(a && a.idCustomerMaster){
                    address[c] = p;
                }
            });
            state.customers = allProducts;
            state.customerContacts = address;
        } 
    },
    setContactHeaders(state, contactTypes){
        localStorage.setItem("contactTypes",JSON.stringify(contactTypes));
        state.contactTypes = contactTypes;
    },
    clearData(state){
        state.customers=[];
        state.saleReps=[];
        state.contactTypes=[]; 
        state.customerContacts={}; 
    }


}


