import {userService} from '@/services/user.service';
import { baseDataService } from '@/services/baseData.service';
import router from '@/router/index' 
//import { cacheService } from '@/services/cache.service';



export const state = {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    status:{},
    userMenu:JSON.parse(localStorage.getItem('userMenu')),
    FullUserName:localStorage.getItem('FullUserName'),
    isOnline: navigator.onLine

}
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({dispatch, commit}, userResult) {

        commit('loginRequest', {email:userResult.email});

        try {
            const {token} = userResult
            await userService.authenticateApi(token);
             
            commit('loginSuccess', userResult, token);
            //cacheService.cacheData()
            let userMenu = await baseDataService.menu();
            commit('setMenu', userMenu);
            
            // if (userResult.isOAuth) {
            //     if(userResult.role==="sales_rep"){
            //         router.push({ path: '/loadata', query: { goto: '/products/list' } })
            //         //router.push('/products/list');
            //     }else{
            //         router.push({ path: '/loadata', query: { goto: '/' } })
            //         //router.push('/loadata');
            //     }
            //     //window.location.href = `${userResult.oauth.redirectUri}`;
            // } else {
            //     router.push({ path: '/loadata', query: { goto: '/' } })
            //     //router.push('/loadata');
            // }
        } catch (error) {
            commit('loginFailure', error);            
        }
    },
    async tryLogin({ commit}) {
        let user = JSON.parse(localStorage.getItem('user'));
         
        if (user && user.token) {
            commit('loginSuccess', user, user.token); 
            let userMenu = await baseDataService.menu();
            commit('setMenu', userMenu);
        }
        return user;
    },
    // Logout the user
    logout({commit}) {
        userService.logout();
        commit('logout',null);
    },
    menu({commit},NewMenu){
        commit('setMenu', NewMenu);
    },
    // register the user
    registeruser({dispatch, commit}, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', {root: true});
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    }
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn() {
        const token = localStorage.getItem('token')
        if (token == null) {
            return false
        } 
        // if (helper.isTokenExpired(token)) {
        //     localStorage.removeItem('token')
        // }
        
        return token != null
    } 
}

export const mutations = {
    setOnline(state, isOnline) { 
        state.isOnline = isOnline;
    },
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, user, token) {
        if(user.SalesAreaArray){
            user.SalesAreaArray = JSON.parse(user.SalesAreaArray);
        }
        state.user = user;
        state.FullUserName = user.fullname;
        state.token = token;
        localStorage.setItem('user',JSON.stringify(user))
        localStorage.setItem('FullUserName',user.fullname)
    },
    setMenu(state, newMenu){
        state.userMenu = newMenu;
        localStorage.setItem('userMenu',JSON.stringify(newMenu))
    },
    loginFailure(state) {
        state.user = null;
    },
    logout(state) {
        state.user = null;
        state.userMenu = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userMenu'); 
        localStorage.removeItem('idCustomerMaster'); 
        localStorage.removeItem('salesArea'); 
        localStorage.removeItem('product_view'); 
        this.commit("salesCustomers/clearData");
    },
    registerRequest(state) {
        state.status = {registering: true};
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};