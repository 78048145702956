<template>
  <!-- Begin page -->
  <div id="layout-wrapper" > 
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <NoAuthHeader></NoAuthHeader>
    <div class="main-content bg-light">
      <div class="page-content">
        <div class="container-fluid p-lg-1 ">
          <slot />
        </div>
      </div>
      <!-- End Page-content -->
      <Footer /> 
    </div>
    <!-- end main content-->
  </div>


</template>
<script> 
import NoAuthHeader from '@/components/NoAuthHeader.vue'
import Footer from "@/components/footer";
export default {
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  components: { 
    NoAuthHeader,
    Footer
  },
  // computed: {
  //   ...layoutComputed,
  // },
  name: "NoAuth",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
  },
  methods: {
     
  },
  mounted: function () {
     
  },
};
</script>
<style>
.page-content{
  min-height: 80vh;
}
</style>