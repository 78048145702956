import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret,faAdd,faPen,faSearch,faShoppingCart,faTrash, faComment, faComments,faTrashRestore,faChevronUp,faChevronDown,faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'

import VueGoodTablePlugin from 'vue-good-table-next';
import vfmPlugin from 'vue-final-modal'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import BootstrapVue from 'bootstrap-vue-3'
import {BToastPlugin} from 'bootstrap-vue-3'
import Vue3MobileDetection from "vue3-mobile-detection";
import {QuillEditor} from '@vueup/vue-quill'
// import the styles
import '@vueup/vue-quill/dist/vue-quill.snow.css'; 
// you can use both themes at the same time and use them interchangeably
// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'
/* add icons to the library */
library.add(...[faUserSecret,faPen,faSearch,faCopyright,faShoppingCart,faTrash,faComments,faTrashRestore,faChevronUp,faChevronDown,faFileExcel])
library.add(faAdd)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
//import DropZone from '@/components/dropzone-vue/DropZone.vue';
// // optionally import default styles
import '@/components/dropzone-vue/assets/main.scss';
import "./assets/bootstrap.min.css" 

import VueEasyLightbox from 'vue-easy-lightbox';

import browserOnline from './plugin/browserOnline.js';
import js_search_plugin from './plugin/js-search-plugin.js';


const app = createApp(App);
app.use(js_search_plugin);
app.use(store);
store.$productSearch = app.config.globalProperties.$productSearch;
window.$productSearch = app.config.globalProperties.$productSearch;
app.config.globalProperties.$store=store;
app.use(router); 
app.use(VueGoodTablePlugin); 
app.use(vfmPlugin); 
app.use(BootstrapVue);
app.use(BToastPlugin);
app.use(VueSweetalert2);
app.use(Vue3MobileDetection);
app.use(VueEasyLightbox);
app.use(browserOnline);
//app.use(DropZone); 
app.component('QuillEditor', QuillEditor);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Datepicker', Datepicker);
app.mount('#app')
