import axios from 'axios'
import phpajax from '@/helper/phpajax';
export const userService = {
    login,
    logout,
    getAll,
    getUserByEmail,
    getUserById,
    createUser,
    updateUser,
    forgotPassword,
    resetPassword,
    me,
    deleteUser,
    userExists,
    fetchAuthUrl,
    verifyToken,
    authenticateApi,
    completeTour
};

async function login(email, password) {
    let bob = phpajax.postObj("LoginGUI","login");
    bob =Object.assign(bob,{"UserName":email,
                            "Password":password})
    return axios.post('ajax.php', bob);
}
// async function getMenu(){
//     const res = await axios.get("menu.php");
//     return res.data;
// }
async function verifyToken(email, token) {
    const res = await axios.post('/auth.php', { email, token });
    return res.data;
}

async function logout() {
    // remove user from local storage to log user out
    //axios.get('/api/auth/logout');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    //delete axios.defaults.headers.common.Authorization;
}

async function getAll() {
        const res = await axios.get('/api/users');
        return res.data;

}

async function me(id) {
    try {
        const res = await axios(`/api/users/me/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function deleteUser(data) {
    try {
        const res = await axios.post('/api/users/delete', { id: data });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserById(id) {
    try {
        const res = await axios(`/api/users/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserByEmail(email) {
    try {
        const res = await axios(`/api/users/by-email/${email}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function userExists(email, username) {
    try {
        const res = await axios.post('/api/users/exist', { email, username });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function createUser(data) {
    try {
        const res = await axios.post('/api/users', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function updateUser(id, data) {
    try {
        const res = await axios.put(`/api/users/${id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function forgotPassword(data) {
    try {
        const res = await axios.post('/api/users/forgot-password/', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function resetPassword(data) {
    try {
        const res = await axios.post('/api/users/reset-password/', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function completeTour(data) {
    const res = await axios.post('/api/users/tour', data);
    return res.data;
}

async function authenticateApi(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('token', token);
}

async function fetchAuthUrl(data) {
  const res = await axios.get(`/api/auth/auth-url/${data}`);
  return res.data;

}