import {baseDataService} from "@/services/baseData.service";

export const state = {
    tags:[]
}
export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async list({commit}) {         

        try { 
            let tags = await baseDataService.tags();             
            commit('setTags', tags);
            return tags;
        } catch (error) { 
            commit('load_storage');
        }
    },
    async listDBLast({state,commit}) {         

        try { 
            if(state.tags && state.tags.length==0){
                let tags = await baseDataService.tags(); 
                await commit('setTags', tags); 
            } 
        } catch (error) {
            console.error("List tags",error)
            commit('load_storage');
        }
        return state.tags;
    } 
}
export const mutations = {
    setTags(state, Tags) {
        state.tags = Tags; 
        localStorage.setItem("tagslist",JSON.stringify(Tags));
         
    },
    load_storage(state){
        let allTags = localStorage.getItem("tagslist");
        if(allTags && allTags.length){            
            state.tags = allTags;
        }  
    }
}