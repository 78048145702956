import axios from 'axios'
import DateFunctions from './DateHelper'
import moment from 'moment'
import _ from 'lodash'
export default {
    async login(UserName,Password)
    {
      let o = {
        "__class":"LoginGUI",
        "UserName":UserName,
        "Password":Password,
        "__call":"login"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async unsubscribe(email)
    {
      let o = {
        "__class":"LoginGUI",
        "email":email ,
        "__call":"unsubscribe"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async createSalesOrder(idCustomerMaster,DetailsLines,additionalDetails)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "idCustomerMaster":idCustomerMaster,
        "items":DetailsLines,
        "additionalDetails":additionalDetails,
        "__call":"create"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async updateSalesOrder(idsalsOrderHeader,idCustomerMaster,DetailsLines,additionalDetails)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "idCustomerMaster":idCustomerMaster,
        "idsalsOrderHeader":idsalsOrderHeader,
        "items":DetailsLines,
        "additionalDetails":additionalDetails,
        "__call":"update"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async activeOrdersByUser()
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "__call":"listYourActiveOrders"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listNewActiveByArea(CustomerCategory,OrderStats)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "CustomerCategory":CustomerCategory,
        "OrderStats":OrderStats,
        "__call":"listNewActiveByArea"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listConfirmedByArea(CustomerCategory,OrderStats,ShipDate)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "CustomerCategory":CustomerCategory,
        "OrderStats":OrderStats,
        "ShipDate":ShipDate,
        "__call":"listOrdersByAreaShipDate"
      } 
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listCustomerOrders(idCustomerMaster)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "idCustomerMaster":idCustomerMaster, 
        "__call":"listCustomerOrders"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listSalesDetails(idsalsOrderHeader)
    { 
      authenticateApi();
      let o = {
        "__class":"orderGUI",
        "idsalsOrderHeader":idsalsOrderHeader, 
        "__call":"listSalesDetail"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async subtotalOrder(CustomerCategory,OrderStats,ShipDateOnly)
    { 
      authenticateApi();
      let Datem = moment(ShipDateOnly).format("YYYY-MM-DD")
      let o = {
        "__class":"orderGUI",
        "CustomerCategory":CustomerCategory,
        "OrderStats":OrderStats,
        "ShipDateOnly":Datem,
        "__call":"subtotalOrder"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async setOrderConfirm(aAllSalesOrderId,ShipDate)
    { 
      authenticateApi();
      let Datem = moment(ShipDate).format("YYYY-MM-DD");
      let ids = _.groupBy(aAllSalesOrderId);
      
      let o = {
        "__class":"orderGUI",
        "allSaleOrders":Object.keys(ids).join(','), 
        "ShipDate":Datem,
        "__call":"setOrderConfirm"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async confirmShipment(aAllSalesOrderId)
    { 
      authenticateApi(); 
      let ids = _.groupBy(aAllSalesOrderId);
      
      let o = {
        "__class":"orderGUI",
        "allSaleOrders":Object.keys(ids).join(','),  
        "__call":"confirmShipment"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async rejectLine(AllSalesOrderDetails,comment)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"orderGUI",
        "allDetails":AllSalesOrderDetails.join(','), 
        "comment":comment,
        "__call":"rejectLine"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    
    async toggleProductImageActive(idproduct,idProductImages)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"productsGUI",
        "idproduct":idproduct, 
        "idProductImages":idProductImages,
        "__call":"toggleProductImageActive"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listProductNoImages()
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"productsGUI", 
        "__call":"listProductNoImages"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async listProductsWithImageCount()
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"productsGUI", 
        "__call":"listProductsWithImageCount"
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async reportDetailsNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"CRMGUI",
        "__call":"detailsReport",
        "idCustomerMaster":idCustomerMaster, 
        "CustomerCategoryArray":CustomerCategoryArray, 
        "OnlyShowCustomerComplaints":OnlyShowCustomerComplaints, 
        "startDate":moment(startDate).format("YYYY-MM-DD"),
        "endDate":moment(endDate).format("YYYY-MM-DD")
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async reportSummeryNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"CRMGUI",
        "__call":"summeryReport",
        "idCustomerMaster":idCustomerMaster, 
        "CustomerCategoryArray":CustomerCategoryArray, 
        "OnlyShowCustomerComplaints":OnlyShowCustomerComplaints, 
        "startDate":moment(startDate).format("YYYY-MM-DD"),
        "endDate":moment(endDate).format("YYYY-MM-DD")
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async reportDetailsContacts(idCustomerMaster,CustomerCategoryArray,startDate,endDate)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"CRMGUI",
        "__call":"detailsContacts",
        "idCustomerMaster":idCustomerMaster, 
        "CustomerCategoryArray":CustomerCategoryArray,
        "startDate":moment(startDate).format("YYYY-MM-DD"),
        "endDate":moment(endDate).format("YYYY-MM-DD")
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    
    async reportFullDetailsContacts(idCustomerMaster,CustomerCategoryArray,CustomerType,ContactType)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"CRMGUI",
        "__call":"detailsContactsFull",
        "idCustomerMaster":idCustomerMaster, 
        "CustomerCategoryArray":CustomerCategoryArray,
        "CustomerType":CustomerType,
        "ContactType":ContactType,
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async reportSalesOrderDetails(idCustomerMaster,CustomerCategoryArray,UserList,CustomerType,startDate,endDate)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"orderGUI",
        "__call":"reportSalesOrderDetails",
        "idCustomerMaster":idCustomerMaster, 
        "CustomerCategoryArray":CustomerCategoryArray,
        "UserList":UserList,
        "CustomerType":CustomerType,
        "startDate":moment(startDate).format("YYYY-MM-DD"),
        "endDate":moment(endDate).format("YYYY-MM-DD")
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async getEmailContacts(autoFilter,saleArea,bulkChecked,ContactType,CustomerType)
    { 
      authenticateApi(); 
      
      let o = {
        "__class":"CRMGUI",
        "__call":"getEmailContacts",
        autoFilter, 
        saleArea,
        bulkChecked,
        ContactType,
        CustomerType,
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async sendEmail(subject,to,body,ImageList,AVFile,AVFileName)
    { 
      authenticateApi();  

      let o = {
        "__class":"CRMGUI",
        "__call":"sendEmail",
        "subject":subject, 
        "debug":process.env.VUE_APP_EMAIL_DEBUG==="true"?"1":"0", 
        "to":to, 
        "body":body, 
        "ImageList":ImageList, 
        "AVFile":AVFile, 
        "AVFileName":AVFileName, 
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    async updateSalesOrderHeader(idSalesOrderHeader,OrderStats){
      authenticateApi(); 
      
      let o = {
        "__class":"orderGUI",
        "__call":"updateSalesOrderOrderStats",
        "idsalsOrderHeader":idSalesOrderHeader, 
        "OrderStats":OrderStats
      }
      let res = await axios.post(process.env.VUE_APP_API_SERVER + "ajax.php",o);

      //console.log(res.data)
      return res.data;
    },
    //////////////////////////////////
    // API X 
    //////////////////////////////////


    async listTable(TableName,fliter="",OrderBy="",Limit=-1,column=""){
      authenticateApi();
      let q = [];
      
      if(OrderBy!=""){
        OrderBy = encodeURI(OrderBy);
        q.push(`order=${OrderBy}`)
      } 
      if(Limit>-1){
        q.push(`&size=${Limit}`);
      } 
      if(Array.isArray(fliter)){
        fliter.forEach(c=>{
          q.push(`filter=${c}`);
        });
      }else{
        if(fliter!==""){
          q.push(`filter=${fliter}`);
        }
      }
      if(column!=""){
        q.push(`include=${column}`);
      }
      let Query = "";
      if(q.length>0){
        Query = q.join("&");
      }

      let res = await axios.get(process.env.VUE_APP_API_SERVER + `xxapi.php/records/${TableName}?${Query}`);
      return res.data;
    },
    async GetRow(TableName,Id){
      let res = await axios.get(process.env.VUE_APP_API_SERVER + `xxapi.php/records/${TableName}/${Id}`);
      return res.data;
    },
    async UpdateRow(TableName,Id,NewValue){
      if(NewValue.hasOwnProperty("LastUpdateDate")){
        NewValue["LastUpdateDate"] =DateFunctions.formatDate(new Date());
      }
      let res = await axios.put(process.env.VUE_APP_API_SERVER + `xxapi.php/records/${TableName}/${Id}`,NewValue);
      return res.records;
    },
    async CreateRow(TableName,NewValue){
      let res = await axios.post(process.env.VUE_APP_API_SERVER + `xxapi.php/records/${TableName}`,NewValue);
      return res.records;
    },
    async DeleteRow(TableName,RowId){
      let res = await axios.delete(process.env.VUE_APP_API_SERVER + `xxapi.php/records/${TableName}/${RowId}`);
      return res.records;
    },
    

    // /* GeoFences */
    // async getGeoFences() {
    //   let res = await axios.get(process.env.VUE_APP_GEO_SERVER + "geofences");

    //   // console.log(res.data)
    //   return res.data;
    // },
    // async deleteGeoFence(GeoFenceId,GeoFenceObj)
    // {
    //   let res = await axios.delete(process.env.VUE_APP_GEO_SERVER + `geofences/${GeoFenceId}`,GeoFenceObj);

    //   return res.data
    // },
    // async updateGeoFence(GeoFenceId,GeoFenceObj)
    // {
    //   let res = await axios.put(process.env.VUE_APP_GEO_SERVER + `geofences/${GeoFenceId}`,GeoFenceObj);

    //   //console.log(res.data)
    //   return res.data;
    // },
    // async createGeoFence(GeoFenceObj)
    // {
    //   GeoFenceObj.type = "geofence";
    //   let res = await axios.post(process.env.VUE_APP_GEO_SERVER + "geofences",GeoFenceObj);

    //   //console.log(res.data)
    //   return res.data;
    // },



}
async function authenticateApi() {
  let token= localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}