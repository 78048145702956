<script>
import router from "@/router";
//import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/topbar"; 
import Footer from "@/components/footer";
 

export default {
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  components: {
    Topbar, 
    Footer,
  },
  // computed: {
  //   ...layoutComputed,
  // },
  name: "verticalLayout",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
  },
  methods: {
    toggleMenu() {
      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },
  mounted: function () {
    if (this.isMenuCondensed == true && window.screen.width >= 992) {
              // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
    }
  },
};
</script>

<template>
  <!-- Begin page -->
  <div id="layout-wrapper" >
    <Topbar /> 
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content bg-light">
      <div class="page-content">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!-- End Page-content -->
      <Footer />
    </div>
    <!-- end main content-->
  </div>


</template>
<style>
.page-content{
  min-height: 75vh;
}
</style>